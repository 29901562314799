.labor-report-container {
  margin-top: 2em !important;
  margin-bottom: 2em !important;
}

.green {
  color: #22543D;
}

.red {
  color: rgb(164, 9, 9);
}