@use '_variables' as *;


.overview-table-row {
  > td {
    padding: 2px;
    text-align: center;
    text-wrap: normal;
    white-space: pre-wrap;
  }

  > th {
    text-align: center;
    padding-left: 2px;
    padding-right: 2px;
  }
}

.incomplete-rows-btn {
  height: 1.8rem !important;
  border-radius: 0 !important;
  max-width: 33%;
  min-width: 7rem !important;
  flex-grow: 1;

  &:focus {
    box-shadow: none !important;
  }
}

.status-btn {
  height: 1.8rem !important;
  border-radius: 0 !important;

  &.cancel {
    background-color: $color-background-red;

    &:hover {
      color: white;
      background-color: $color-background-dark-red;
    }
  }

  &.complete {
    background-color: $color-background-green;

    &:hover {
      color: white;
      background-color: $color-background-dark-green;
    }
  }
}

.incomplete-rows-td {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  max-width: 22rem;
}

.incomplete-rows-container {
  display: flex;
  flex-flow: wrap;
  gap: 1rem;
}

.wo-overview-action {
  width: 20rem;
  flex-grow: 1;
  white-space: pre-wrap;
}

.wo-overview-region {
  max-width: 14rem;
  white-space: pre-wrap;
}

.label {
  font-weight: 600;
}

.wo-table-filter-container {
  margin-top: 1rem !important;
  width: 98%;
  display: flex;
  flex-direction: column;

  > .filter-btn-container {
    margin: 0.5rem 0 0.5rem 0;
    width: 100%;
    display: flex;
    gap: 5px;
    justify-content: flex-start;
    align-items: flex-start;

    > .filter-btn {
      max-width: fit-content !important;
    }
  }

  > .filter-container {
    margin-top: 1rem !important;
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

}

#daterangepicker {
  padding-left: 5px;
  padding-right: 5px;
  width: 13rem;
  text-align: center;

  &.active {
    background: white;
    background-color: white;
    border-color: cornflowerblue;
  }
}