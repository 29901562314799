.tab-item {
  border-bottom: 2px solid transparent;
}
.tab-item:hover {
  text-decoration: none !important;
  border-bottom: 2px solid black;
  outline: none;
}

.header {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.css-11af110 {
  height: fit-content !important;
}

