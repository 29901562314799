.imgcarousel-container {
  font-family: 'Helvetica', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  height: 100vh;
  width: 100vw;
  margin: 0 !important;
  padding: 0 !important;
  max-width: 100vw !important;
}

.imgcarousel-body {
  position: relative;
  overflow-x: hidden;
  height: fit-content !important;
}

.imgcarousel-body-thumbnail-container {
  overflow: hidden;
  height: 100%;
  box-sizing: border-box;
  max-width: 100px;

  > .thumbail-img {
    height: 130%;
    width: 130%;
    max-width: 130%;
    max-height: 130%;
    object-fit: cover;
    position: center;
  }
}

.imgcarounsel-plant-name {
  width: 20rem;
}

.modal-body {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 2rem 1rem 1rem 1rem !important;
  overflow: hidden;

  > .main-box {
    width: calc(100% - 30rem);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 3rem);

    > img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
}


.imgcarousel-nav {
  display: flex;

  > .imgcarousel-nav-sidebar {
    max-width: 6rem;
    padding-right: 1rem;
  }
}

.nav-column {
  display: flex;
  flex-direction: column;
  min-width: 28rem;
  width: 29rem;
  padding-right: 1rem;
  overflow-y: hidden;
  border-right: 2px solid lightgray;
  margin-right: 1rem;

  > .comparison-box {
    display: flex;
    flex-direction: column;
    flex-grow: 4;
    height: calc(100vh - 26rem);
    padding: 0.5rem 0 0 0 !important;
    justify-content: flex-start;
    align-items: flex-start;
    border-top: 2px solid lightgray;
  }
}

.imgcarousel-nav-container {
  width: 100%;
  height: 23rem;
  display: flex;
  flex-direction: column;

  > .plant-id-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: bold;
  }

  > .plant-position-year {
    text-align: center;
  }

  > .plant-position-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    > .plant-positions {
      margin: 2px 5px;
      display: flex;
      flex-direction: column;
      text-align: center;

      :last-child {
        width: 100%;
      }

      > .date {
        min-width: 3.5rem;
        padding: 0.5rem;
        text-align: center;
      }

      > .plant-position-cell {
        flex-grow: 1;
        font-size: 0.9rem;
        padding: 0.1rem 0.2rem 0.1rem 0.2rem;
        background-color: #bfe3bf;
        color: #294f21;
        width: 100%;
        box-sizing: border-box;
        border: 2px solid transparent;

        &:hover {
          background-color: #1c401c;
          color: #f5fff5;
          cursor: pointer;
        }

        &.highlight {
          border: 2px solid darkgreen;
        }
      }
    }
  }
}

.magnify-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.box-box {
  width: 100%;
  height: 100%;
  max-height: calc(100% - 2.2rem);
  max-width: 100%;

  > .magnifier-nav {
    width: 100%;
    min-height: 2rem;
    border: 2px solid grey;
    box-sizing: border-box;
    margin: 1rem 0;
  }

  > img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.magnified {
  width: 100%;
  max-width: inherit;
  height: inherit;
  flex-grow: 2;
  overflow: scroll;

  > img {
    width: fit-content;
    height: auto;
    max-width: fit-content;

    &.ratio-2 {
      width: 300%;
      max-width: 300%;
    }

    &.ratio-4 {
      width: 400%;
      max-width: 400%;
    }
  }
}

.magnify-btn-container {
  > .magnify-btn {
    font-family: 'Helvetica', sans-serif;
    font-size: 0.9rem;
    font-weight: 600;
    background-color: #f7edb5;
    color: #b37c24;
    padding: 0.1rem 1rem;
    margin-bottom: 0.5rem;
    border: 2px solid transparent;
    border-right: 2px solid #edcdad;

    &:hover {
      background-color: #693706;
      color: white;
    }

    &.highlight {
      background-color: #e6ca85;
      color: #473300;
    }
  }

  :first-child {
    border-left: 2px solid transparent;
  }

  :last-child {
    border-right: 2px solid transparent;
  }
}

.center-child {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nivo-button {
  padding: 5px 15px !important;
  height: fit-content !important;
  border-radius: 0 !important;
  box-sizing: border-box;
  color: #3d3d3d;

  &:focus {
    border: none;
    box-shadow: none !important;
  }
}