.App {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

a:focus {
  box-shadow: none !important;
}