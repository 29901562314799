.overview-table-row > td {
  padding: 2px;
  text-align: center;
  text-wrap: normal;
  white-space: pre-wrap;
}
.overview-table-row > th {
  text-align: center;
  padding-left: 2px;
  padding-right: 2px;
}

.incomplete-rows-btn {
  height: 1.8rem !important;
  border-radius: 0 !important;
  max-width: 33%;
  min-width: 7rem !important;
  flex-grow: 1;
}
.incomplete-rows-btn:focus {
  box-shadow: none !important;
}

.status-btn {
  height: 1.8rem !important;
  border-radius: 0 !important;
}
.status-btn.cancel {
  background-color: #f5b0ab;
}
.status-btn.cancel:hover {
  color: white;
  background-color: #6e0902;
}
.status-btn.complete {
  background-color: #bfe3bf;
}
.status-btn.complete:hover {
  color: white;
  background-color: #1c401c;
}

.incomplete-rows-td {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  max-width: 22rem;
}

.incomplete-rows-container {
  display: flex;
  flex-flow: wrap;
  gap: 1rem;
}

.wo-overview-action {
  width: 20rem;
  flex-grow: 1;
  white-space: pre-wrap;
}

.wo-overview-region {
  max-width: 14rem;
  white-space: pre-wrap;
}

.label {
  font-weight: 600;
}

.wo-table-filter-container {
  margin-top: 1rem !important;
  width: 98%;
  display: flex;
  flex-direction: column;
}
.wo-table-filter-container > .filter-btn-container {
  margin: 0.5rem 0 0.5rem 0;
  width: 100%;
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  align-items: flex-start;
}
.wo-table-filter-container > .filter-btn-container > .filter-btn {
  max-width: fit-content !important;
}
.wo-table-filter-container > .filter-container {
  margin-top: 1rem !important;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

#daterangepicker {
  padding-left: 5px;
  padding-right: 5px;
  width: 13rem;
  text-align: center;
}
#daterangepicker.active {
  background: white;
  background-color: white;
  border-color: cornflowerblue;
}

