.react-datepicker-wrapper {
    display: block;
}
.react-datepicker__input-container input {
    padding-left: 20px;
    background-color: #F6F6F4;
    border-color: #CDD0D9;
    border-radius: 4px;
    border-width: 1px;
    height: 40px;
    width: 200px;
}