.workorder-routine-table > thead > tr > th {
  padding-top: 0.2rem !important;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-bottom: 1rem;
  text-align: center;
}

.routine-cell-weekday {
  max-width: 1.5rem;
}

.workorder-routine-body-tr > td {
  font-size: 0.9rem;
  text-align: center;
  padding-top: 0.2rem !important;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-bottom: 0.2rem;
}

