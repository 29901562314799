table.report-table td {
  padding: 0.5rem !important;
  text-align: center;
}

td.concern-validity {
  width: 10rem;
  max-width: 10rem;
  white-space: normal;
}

td.concern-type {
  width: 20rem;
  max-width: 20rem;
  white-space: normal;
}

