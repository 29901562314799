.react-datepicker-popper {
  z-index: 10;
}

.nivo-button.highlight {
  background-color: #434445;
  color: #ebedf0;
}
.nivo-button.highlight:hover {
  background-color: #434445;
  color: #ebedf0;
}

