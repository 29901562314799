$color-completed: #70AD47;
$color-normal: #70AD47;
$color-warning: #FF6B00;
$color-needs-review: #FF6B00;
$color-started: #3488D4;
$color-distributed: #C4C4C4;

$color-background-green: #bfe3bf;
$color-background-yellow: #f7edb5;
$color-background-orange: #e6ca85;
$color-background-red: #f5b0ab;

$color-background-dark-green: #1c401c;
$color-background-dark-brown: #693706;
$color-background-dark-red: #6e0902;