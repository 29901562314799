:root {
  --chakra-space-6: 1.5rem;
}

td {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

button:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.align-center {
  align-items: center;
  justify-content: center;
  text-align: center !important;
}

.pointer {
  cursor: pointer;
}

.border-bottom {
  border-bottom: 2px solid darkgrey;
}

.chakra-td {
  text-align: start;
  -webkit-padding-start: var(--chakra-space-6);
  padding-inline-start: var(--chakra-space-6);
  -webkit-padding-end: var(--chakra-space-6);
  padding-inline-end: var(--chakra-space-6);
  padding-top: var(--chakra-space-4);
  padding-bottom: var(--chakra-space-4);
  line-height: var(--chakra-lineHeights-5);
  border-bottom: var(--chakra-borders-1px);
  border-color: var(--chakra-colors-gray-100);
}

.chakra-th {
  font-family: var(--chakra-fonts-heading);
  font-weight: var(--chakra-fontWeights-bold);
  text-transform: uppercase;
  letter-spacing: var(--chakra-letterSpacings-wider);
  text-align: start;
  -webkit-padding-start: var(--chakra-space-6);
  padding-inline-start: var(--chakra-space-6);
  -webkit-padding-end: var(--chakra-space-6);
  padding-inline-end: var(--chakra-space-6);
  padding-top: var(--chakra-space-3);
  padding-bottom: var(--chakra-space-3);
  line-height: var(--chakra-lineHeights-4);
  font-size: var(--chakra-fontSizes-xs);
  color: var(--chakra-colors-gray-600);
  border-bottom: var(--chakra-borders-1px);
  border-color: var(--chakra-colors-gray-100);
}

.badge-like-button {
  display: inline-block !important;
  white-space: nowrap !important;
  vertical-align: middle !important;
  text-transform: uppercase !important;
  display: inline-block !important;
  white-space: nowrap !important;
  vertical-align: middle !important;
  padding-inline-start: 0.3rem !important;
  padding-inline-end: 0.3rem !important;
  text-transform: uppercase !important;
  font-size: 0.8rem !important;
  line-height: 0.1rem !important;
  font-weight: bold !important;
  border-radius: 1px !important;
  height: 1.5rem !important;

  &.green {
    color: #22543D;
    background-color: #C6F6D5;
    outline: none;
    border: none;

    &:hover {
      color: #C6F6D5 !important;
      background-color: #22543D !important;
    }
  }

  &.purple {
    color: #44337A;
    background-color: #E9D8FD;
    outline: none;
    border: none;

    &:hover {
      color: #E9D8FD !important;
      background-color: #44337A !important;

    }
  }
}

.text-green {
  color: #22543D;
}

.text-red {
  color: rgb(164, 9, 9);
}
